:root {
  --off-white: #fafafa;
}

.App {
  text-align: center;
  background-color: #282c34;
}

.App-logo {
  width: 90%;
  pointer-events: none;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: lightgrey;
}

.small-images {
  height: 20vmin;
  pointer-events: none;
}

.wide-border {
  pointer-events: none;
  background-color: var(--off-white);
  color: darkslategray;
  width: 90%;
}

.stats {
  width: 90%;
}

.section {
  margin: 2rem;
}

.nav {
  background-color: var(--off-white);
  position: sticky;
  top: 0px;
  width: 100%;
  height: 50px;
  z-index: 1000;
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.15);
}

.nav-logo {
  height: 50px;
  width: 50px;
}

.nav-logo:hover {
  cursor: pointer;
}

.nav .nav-content {
  max-width: 900px;
  padding: 0rem 3rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
  height: 100%;
}

.nav-item {
  display: inline;
  margin-left: 2rem;
  color: #333;
}

.nav-item:hover {
  color: black;
  cursor: pointer;
}

.App-link {
  color: #61dafb;
}
